import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout.js'
import Container from '../components/Container.js'
import Footer from '../components/Footer.js'
import styles from './ContentPageTemplate.module.sass'
import '../pages/index.sass'

const ContentPageTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout isHeaderFilled>
      <div className={styles.contentPage}>
        <Container>
          <div className={styles.runningText}>
            <h1>{frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </Container>
      </div>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

export default ContentPageTemplate
